/* Utility: Package block
-------------------------------------------------- */

.package-block .row {
    padding-left: 10px;
    padding-right: 10px;
}

.package-block .text-container {
    padding-left: 30px;
}

.package-block.main-content {
    margin-bottom: 40px;
} 

.package-block .card-headline,
.package-block .card-labels {
    text-align:center;
}

.package-block .card-container {
    position: relative;
}

.package-block .card-headline {
    margin-bottom: 30px;
}

.package-block .main-content ul,
.package-block .text-container ul{
    margin: 0;
    padding: 0;
}

.package-block .main-content ul li {
    margin-bottom: 20px;
    font-size:20px;
    display:list-item;
    list-style-type: disc;
}

.package-block .card-meta ul li {
    list-style: none;
}

.package-block .pull-left h3 {
    margin-bottom: 20px;
}

.package-block h2 a,
.package-block h3 a,
.package-block h4 a {
    text-decoration: underline;
}

.package-block.light .hammer,
.package-block.light h1,
.package-block.light h1 a,
.package-block.light h2,
.package-block.light h2 a,
.package-block.light h4,
.package-block.light h4 a,
.package-block.light h3,
.package-block.light h3 a,
.package-block.light li,
.package-block.light li time,
.package-block.light .card-lead p,
.package-block.light .label,
.package-block.light span,
.package-block.light .card-labels a {
    color: rgba(255,255,255,1);
}

.package-block .full-dark-container {
    background-color: #231f20;
    opacity: .3;
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.package-block .additional-content ul li {
    text-decoration: underline;
}

.package-block .image img{
    padding-bottom:20px;
}

.package-block .card-meta ul li {
    padding-bottom:0;
    margin-bottom: 0;
    font-size: 16px;
}

.package-block  div.card-headline > a:hover,
.package-block  div.card-headline > h2:hover,
.package-block  div.card-headline > h3:hover,
.package-block  div.card-headline > h4:hover {
    text-decoration: none;
}

.package-block .card-headline h2,
.package-block .card-headline h3,
.package-block .card-headline h4 {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.package-block .card-meta li {
    font-size: 14px !important;
    line-height: 1.42857143;
    padding-bottom: 5px !important;
}

.package-block .card-meta li time {
    color: inherit;
}

.package-block .related-links h2,
.package-block .related-links h3,
.package-block .related-links h4 {
    padding-bottom: 10px;
}

.package-block:hover .full-dark-container {
    opacity: 0;
}

.package-block .text-container {
    word-break: break-word;
}